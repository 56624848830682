<template xmlns:el-col="http://www.w3.org/1999/html">
  <div style="display: flex; height: 100%" >

    <ContractPermissionWindow
            :show_permission="true"
            ref="permissionWindow"
            :initial-data="{permissionAccessUidList,permissionAccessOrganizationIdList,organization_permission,user_permission}"
            :confirm-call-back="savePublishedTemplatePermission"
    ></ContractPermissionWindow>

    <div  v-if="publishedData || publishedTemplate.title" style="width: 100%; height: 100%">
      <div>
        <div class="contract-edit-header">
          <div style="display: flex" id="contract-edit-header-left">
            <ExitBtn :disabled="history.length == 1" :handler="handleExitBtnClicked"></ExitBtn>

            <div style="text-align: left;padding-left:20px">
              <edit-title
                      v-if="publishedTemplate['title']"
                      v-model:title="publishedTemplate['title']"
                      v-model:mode="editTitle"
                      :max-length="20"
                      :disableTooltip="!canUpdate"
                      :upload-fn="(title)=>updateTitle(title)"
              >

              </edit-title>
              <!--              <el-tooltip-->
              <!--                      class="box-item"-->
              <!--                      effect="dark"-->
              <!--                      content="重命名"-->
              <!--                      placement="bottom"-->
              <!--                      :disabled="!canUpdate"-->
              <!--              >-->
              <!--                <div id="template-wrap">-->

              <!--                  <el-input  id="editTitleInput"-->
              <!--                             class="template-title"-->
              <!--                             ref="input"-->
              <!--                             size="small"-->
              <!--                             v-model="publishedTemplateEditData['title']"-->
              <!--                             @blur="updateTitle"-->
              <!--                             @keydown="handleKeydown"-->
              <!--                             @compositionstart="handleCompositionstart"-->
              <!--                             @compositionend="handleCompositionend"-->
              <!--                             :style="{-->
              <!--                               visibility: editTitle ? 'visible' : 'hidden',-->
              <!--                               display: 'inline-block',-->
              <!--                               width: '300px',-->
              <!--                               position: editTitle ? 'relative' : 'absolute'}">-->
              <!--                  </el-input>-->
              <!--                  <span-->
              <!--                      class="template-title"-->
              <!--                      ref="publishedTemplateTitle"-->
              <!--                      :style="{-->
              <!--                        left:0,-->
              <!--                        visibility: editTitle ? 'hidden' : 'visible',-->
              <!--                        position: editTitle ? 'absolute' : 'relative'}"-->
              <!--                      @click="onTitleClick">-->
              <!--                    {{ publishedTemplate['title'] }}-->
              <!--                    </span>-->
              <!--                </div>-->
              <!--              </el-tooltip>-->


              <!--              <div class="contract-wrap">-->
              <!--                <span class="published-template-title">-->
              <!--                  {{publishedTemplate['title']}}-->
              <!--                </span>-->
              <!--              </div>-->

              <div id="topBar-left-toolBar">
                <div style="padding-right: 15px; border-right: solid 1px #ddd">
                  <div>
                    <span class="stage-circle"
                          :class="{'stage-white': !isNotNull(templateId),
                          'bg-dandelion': isNotNull(templateId) && publishedTemplate.approvalStatus === 0,
                          'bg-mint': isNotNull(templateId) && publishedTemplate.approvalStatus === 1,
                          'bg-mona-lisa': isNotNull(templateId) && publishedTemplate.approvalStatus === 2}"></span>
                    <span v-html="getTemplateStatusText()"></span>
                  </div>
                </div>
                <ul
                        class="contract-menu"
                        @click="menuActive = true"
                        @mouseleave="menuActive = false"
                >
                  <li
                          class="contract-menu-item"
                          @click="FileMenuVisible = !FileMenuVisible"
                          @mouseenter="FileBtnHover"
                          @mouseleave="FileMenuVisible = false"
                  >
                    <span>文件</span>
                    <ul v-show="FileMenuVisible" class="dropdown-menu">
                      <li
                              class="dropdown-menu-item"
                              :class="{unselectable: !canUpdate}"
                              @click="editPublishedTemplate"
                      >
                        <i class="el-icon-edit-outline"></i><span>编辑</span>
                      </li>
                      <div style="border-bottom: solid 1px #ddd; margin: 10px 0"></div>
                      <li
                              :class="{ unselectable: !canUpdate}"
                              class="dropdown-menu-item"
                              @click="onRenameBtnClick"
                      >
                        <i></i><span>重命名</span>
                      </li>
                      <li
                              :class="{unselectable: !canUpdate}"
                              class="dropdown-menu-item"
                              @click="deletePublishedTemplate"
                      >
                        <i class="el-icon-delete"></i><span>删除</span>
                      </li>

                    </ul>
                  </li>
                  <li
                          class="contract-menu-item"
                          @click="settingMenuVisible = !settingMenuVisible"
                          @mouseenter="settingBtnHover"
                          @mouseleave="settingMenuVisible = false"
                  >
                    <span>设置</span>
                    <ul v-show="settingMenuVisible" class="dropdown-menu">
                      <li
                              :class="{ unselectable: !canUpdate}"
                              class="dropdown-menu-item"
                              @click="$refs.permissionWindow.openWindow()"
                      >
                        <i></i><span>使用权限</span>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>

          </div>
          <div id="contract-edit-header-right">
          </div>
        </div>
      </div>
      <div ref="agreement" class="agreement-container" >
        <div id="pdf-container" class="custom-scroller">
          <div class="main-container">
            <div
                    style="
              position: sticky;
              top: 0;
              height: 28px;
              width: 100%;
              background: #f8f9fa;
              z-index: 11;
            "
            ></div>
            <div ref="content" class="content-container">
              <div class="preview-container">
                <div class="pdf-tool-panel bzbb">
                  <div class="pdf-tool-left"></div>
                  <div id="pdf-tool-right" style="position: absolute;right: 10px;" v-if="canUpdate">
                    <el-button
                            class="border-rect-btn"
                            size="small"
                            style="margin-right: 10px;"
                            @click="editPublishedTemplate()"
                    >
                      编辑
                    </el-button>
                  </div>
                </div>
                <el-main v-if="initDataSuccess"
                         id="editor-content-el-main">
                  <!--                  style="overflow: visible;height: 100%;overflow-y: hidden;"-->
                  <doc-content ref="content"
                               @editor-messages="handelEditorMessages"
                               @editor-content-changed="editorContentChanged"
                               @editor-ready="editorInitReady"
                               :getSetting="getSetting"
                               :fileId="fileId"
                               :content="docContent"
                               :variableConfig="variableConfig"
                               :defaultMode="'answer_model'"/>
                </el-main>

              </div>

              <div class="tool-container" >
                <div class="sticky">
                  <div style="text-align: left; padding-top: 10px;margin-bottom:10px">
                    <div style="display: inline-block;">
                      <el-button v-if="fileStatus === 0"
                                 cancelcustom
                                 size="small"
                                 style="background-color: #80cbff;border-color: #80cbff;color: white;border-radius: 0;margin-right: 20px"
                                 @click="publishSubmit()">
                        发布审批
                      </el-button>

                      <contract-popover v-else-if="fileStatus === 2" style="margin-right: 20px">
                        <template #reference>
                          <el-button cancelcustom type="primary" style="color:#fff !important;border-radius: 0;" size="small">通过/驳回 <i class="el-icon-arrow-down"></i></el-button>
                        </template>
                        <div>
                          <div class="draft-operation-item draft-operation-pass" @click="approvalPassed()">通过审批</div>
                          <div class="draft-operation-item draft-operation-decline" @click="rejectDialogVisible=true">驳回审批</div>
                        </div>

                      </contract-popover>
                      <el-button v-else-if="fileStatus === 3"
                                 cancelcustom
                                 size="small"
                                 style="background-color: rgb(226,140,38);border-color: rgb(226,140,38);color: white;border-radius: 0;margin-right: 20px">
                        审核中
                      </el-button>
                      <el-button v-else-if="fileStatus === 4"
                                 cancelcustom
                                 size="small"
                                 style="background-color: #2cc6ab;color: white;border-radius: 0;margin-right: 20px">
                        审核通过
                      </el-button>
                      <el-button v-if="fileStatus === 5"
                                 cancelcustom
                                 size="small"
                                 style="background-color: #80cbff;border-color: #80cbff;color: white;border-radius: 0;margin-right: 20px"
                                 @click="rePublishSubmit()">
                        重新发布
                      </el-button>
                    </div>
                  </div>
                  <div style="border-bottom: solid 1px #ddd; margin: 10px 0"></div>
                  <div>
                    <el-tabs v-model="toolActiveName" @tab-click="toolTabClick">
                      <el-tab-pane label="设置" name="setting">
                        <el-scrollbar class="published-template-setting-container" :height="settingHeight">
                          <div v-for="(key, index) in ['title', 'description']"
                               class="ptc"
                               @mouseenter="onTemplateInfoMouseenter(`${key}`)"
                               @mouseleave="onTemplateInfoMouseleave(`${key}`)"
                               :height="key === 'title' ? 30 : 90">
                            <div class="ptc-key">
                              <span v-html="key === 'title' ? '模板名称': '模板描述'"></span>
                            </div>
                            <div v-if="templateInfoMode[key] === 'edit' && key === 'title'"
                                 class="ptc-value">
                              <el-input
                                      :id="`template-${key}-input`"
                                      autocomplete="off"
                                      placeholder=""
                                      type="text"
                                      v-model="publishedTemplateEditData[key]"/>
                            </div>
                            <div v-else-if="templateInfoMode[key] === 'edit' && key === 'description'"
                                 class="ptc-value">
                              <el-input
                                      :id="`template-${key}-input`"
                                      autocomplete="off"
                                      placeholder=""
                                      :rows="3"
                                      type="textarea"
                                      resize="false"
                                      v-model="publishedTemplateEditData[key]"/>
                            </div>
                            <div v-else class="ptc-value text-m3">
                              <span>{{publishedTemplate[key]}}</span>
                            </div>
                            <div
                                    v-if="templateInfoMode[`${key}`] === 'read'"
                                    class="edit-btn"
                                    title="编辑"
                                    @click.stop="editTemplateInfo(key)">
                              <img
                                      class="relation_img"
                                      src="../assets/images/editor.png"
                              />
                            </div>
                            <el-button
                                    size="mini"
                                    style="border-radius: 0;"
                                    type="default"
                                    v-if="templateInfoMode[key] === 'edit'"
                                    class="change-overview-cancel-btn"
                                    @click="changeTemplateInfoCanceled(key)"
                            >取消
                            </el-button>
                            <el-button
                                    size="mini"
                                    type="primary"
                                    v-if="templateInfoMode[`${key}`] === 'edit'"
                                    class="change-overview-confirm-btn"
                                    @click="saveTemplateInfo(key)"
                            >保存
                            </el-button>
                          </div>
                          <el-divider></el-divider>

                          <div v-for="(key, index) in ['categoryId', 'metaApprovalProcessId']"
                               class="ptc">
                            <div class="ptc-key">
                              <span v-html="key === 'categoryId' ? '模板分类': '配置审批流'"></span>
                              <el-tooltip
                                      class="item"
                                      effect="dark"
                                      placement="top"
                                      :content="key === 'categoryId' ? '分类后，该模板将会出现在模板库的对应类别中。': '配置后，基于该模板起草的合同，将沿用此审批流进行审核'" >
                                <i class="el-icon-info"></i>
                              </el-tooltip>
                            </div>
                            <div class="ptc-value">
                              <SelectionCreating v-if="initCategoryStatus === 2 && key === 'categoryId'"
                                                 ref="category-selection-creating"
                                                 :options="getTemplateInfoSelectionOptions(key)"
                                                 :bindData="publishedTemplateEditData[key]"
                                                 :labelKey="'name'"
                                                 :valueKey="'id'"
                                                 :selectChange="onCategoryChange"
                                                 :createConfirm="createPublishedTemplateCategory"
                                                 :disabled="!canUpdate"
                                                 :remove="deleteCategory">

                              </SelectionCreating>
                              <el-select v-else
                                         v-model="publishedTemplateEditData[`${key}`]"
                                         class="m-2"
                                         placeholder="Select"
                                         @change="onTemplateInfoChanged(key)"
                                         :disabled="!canUpdate">
                                <el-option
                                        v-for="item in getTemplateInfoSelectionOptions(key)"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                >
                                </el-option>
                              </el-select>
                            </div>
                          </div>
                          <div class="ptc conclusive-firstArea conclusive-area">
                            <div class="ptc-key">
                              <span class="conclusive-label-text">使用权限</span>
                              <el-tooltip
                                      class="item"
                                      effect="dark"
                                      placement="top"
                                      content="选择可以使用该模板的部门/人员。" >
                                <i class="el-icon-info"></i>
                              </el-tooltip>
                            </div>
                            <div class="ptc-value-permission">
                              <Members :members="getMembers" main_placement="bottom" sub_placement="right">
                                <template #reference>
                                  <user-icon style="margin-right: -8px"  v-for="(item,index) in publishedTemplateAccessors.slice(0,5)" :key="index" :name="item.objectName" :uid="item.objectName" :url="item.headImgUrl"></user-icon>
                                  <user-icon v-if="publishedTemplateAccessors.length>5" size="small" uid="count" :name="'+'+(publishedTemplateAccessors.length-5)" :count="2"></user-icon>
                                </template>
                              </Members>
                            </div>
                            <span class="conclusive-firstArea-icon show"
                                  @click="onPermissionClicked" style="margin-top: 2px;">
                              <img
                                      style="width: 20px;height:20px;"
                                      src="../assets/images/editor.png"
                              />
                            </span>
                          </div>
                          <div class="ptc conclusive-firstArea conclusive-area">
                            <div class="ptc-key" style="cursor: pointer;">
                              <div class="conclusive-label-icon" style="margin-top: 9px;">
                                <svg-icon name="tag" width="100%" height="100%"></svg-icon>
                              </div>
                              <span class="conclusive-label-text"
                              >标签</span>
                              <el-tooltip
                                      class="item"
                                      effect="dark"
                                      placement="top"
                                      content="从该模板创建的合同，都将承继此项下的标签" >
                                <i class="el-icon-info"></i>
                              </el-tooltip>
                            </div>
                            <div class="ptc-value-tags ptc-value">
                              <TagComposition
                                      class="excluded"
                                      ref="tagComposition"
                                      style="flex:1;"
                                      :selectable-tags-data="allTagsData"
                                      :selected-tags-data="tags"
                                      :list-z-index="11"
                                      :list-width="'auto'"
                                      :list-max-height="200"
                                      :prevent-wrapper-modify-resolution="true"
                                      :placeholder="tagCompositionInputPlaceholder"
                                      :external-requests="{
                                        addTag:(name, type) => publishedTemplateAddTag(name, type),
                                        removeTagRelation:(tagId) => publishedTemplateDeleteTag(tagId),
                                        completeTag:(tagId,formData) => publishedTemplateCompleteTag(tagId,formData),
                                        cancelTagCompleteStatus: (tagId) => publishedTemplateCancelTagCompleteStatus(tagId)
                                      }"
                                      @modeChanged="handleTagCompositionModeChanged"
                                      @tagResolved="handleTagCompositionTagResolved"
                                      @containerTagDataAppended="()=>{}" />

                            </div>
                            <span class="conclusive-firstArea-icon"
                                  :class="{show:!tagEditMode}" style="margin-top: 11px;"
                                  @click="clickTagsEdit">
                              <img
                                      style="width: 20px;height:20px;"
                                      src="../assets/images/editor.png"
                              />
                            </span>
                          </div>
                        </el-scrollbar>
                      </el-tab-pane>
                      <el-tab-pane label="留痕" name="record">
                        <div class="contract_version_item" :height="settingHeight">
                          <el-scrollbar>
                            <el-timeline style="padding: 0 6px">
                              <el-timeline-item
                                      v-for = "(auditing , index) in Auditings"
                                      color="#507EFF"
                                      :key="index"
                                      style="padding-bottom: 10px ; padding-top: -10px"
                              >
                                <p style="margin-top:0;color: #5B5B5E;font-weight: bold">{{Dataformat(auditing.createTime)}}</p>
                                <p v-for=" insideAuditing in auditing.auditingItems">
                                <p style="color: #919190">{{HourMinuteformat(insideAuditing.createTime)}}</p>
                                <p style="color: var(--font-gray)">{{insideAuditing.messageInfo}}</p>
                                </p>
                              </el-timeline-item>
                            </el-timeline>
                          </el-scrollbar>
                        </div>
                      </el-tab-pane>
                    </el-tabs>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
    <div v-else-if="notfound" style="width: 100%">
      <error
              :error-msg="'模版已被删除'"
              :btn-handler="()=>{$router.push('/index')}"
              :btn-msg="'返回主页'"
      ></error>
    </div>
    <el-dialog title="确认驳回" v-model="rejectDialogVisible" append-to-body>
      <span>请输入驳回理由</span>
      <el-input type="textarea" v-model="rejectReason"></el-input>
      <template #footer>
        <el-button @click="rejectDialogVisible = false;">取消</el-button>
        <el-button type="danger" @click="approvalReject();"
        >驳回</el-button
        >
      </template>
    </el-dialog>


  </div>
</template>

<script>
  import config from "../../src/config";
  import DocContent from "../components/DocContent";
  import {ElLoading, ElMessage, ElMessageBox} from "element-plus";
  import {debounce, getHashColor, getUserName, itemJsonStrPro2Obj} from "../util";
  import {BehaviorPermission,checkUserBehaviorPermission} from "../components/common/permissionUtil";
  import ContractPermissionWindow from "./contract/ContractPermissionWindow";
  import logo from "../assets/images/metasotalog.png";
  import ContractPopover from "../components/ContractPopover";
  import SelectionCreating from "../components/SelectionCreating";
  import TagComposition from "../components/tag/TagComposition";
  import SvgIcon from "../components/SvgIcon/svgIcon";
  import {hashCode, uuidGen, colorLog, MetaMessage,copyToClipboard} from "../util";
  import {VARIABLE_TYPE_OPTIONS} from "../constant/question";
  import {
    getTeamMetaApprovalProcesses,
    getTeamPublishedTemplateCategories,
    getTemplateSettings,
    getTemplateFileMeta,
    updatePublishedTemplate,
    updateTemplatePublishPermissionAccess,
    passPublishedTemplate,
    rejectPublishedTemplate,
    putTemplatePublish,
    rePublishPublishedTemplate, getMetaProData,
    getDefaultTemplateSetting,
    deletePublishedTemplate,
    deleteTemplateFile,
    getTemplateAuditing,
    addPublishedTemplateCategory,
    deletePublishedTemplateCategory,
    getTags,
    addPublishedTemplateTag,
    deletePublishedTemplateTag,
    addTag,
  } from "../api/api";
  import * as request from '../api/api';
  import {globalTagClick} from "../assets/js/tag/tag";
  import EditTitle from "../components/EditTitle";
  import UserIcon from "../components/UserIcon";
  import Members from "../components/Members";
  import Error from "./Error";
  import ExitBtn from "../components/universalUI/ExitBtn";

  export default {
    name: "templatePublish",
    components: {
      ExitBtn,
      Error,
      EditTitle,
      Members,
      UserIcon,
      ContractPopover,
      DocContent,
      ContractPermissionWindow,
      SelectionCreating,
      TagComposition,
      SvgIcon
    },
    data: function () {
      return {
        ElMessage,
        copyToClipboard,
        history:window.history,
        metaMessage: new MetaMessage(),
        userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
        teamInfo: JSON.parse(sessionStorage.getItem("teamInfo")),
        teamId: '',
        fileId: this.$route.query.fileId,
        templateId: this.$route.query.templateId,
        colorLog,
        approvalProcess: {},
        hasMetaApprovalProcess: false,
        isApproval: false,
        isCreator: false,
        config: config,
        logo: logo,
        toolActiveName: "setting",
        titleEditMode: false,
        FileMenuVisible: false,
        settingMenuVisible: false,
        exportMenuVisible: false,
        menuActive: false,
        teamMembers: null,
        metaApprovalData: {
          metaApprovalData: {
            process: {},
          },
        },
        getHashColor,
        showApprovalDetailDialogVisible: false,
        renderedPageNumbers:new Set(),
        //组件temp
        tempTags:[],
        loadingInstance:null,
        //合同问卷
        getUserName,
        versionSeries:[],
        checkUserBehaviorPermission,
        BehaviorPermission,

        docContent: null,
        initDataSuccess: false,
        settingHeight: document.body.clientHeight - 242,
        variableConfig: {
          showVariable: false
        },
        publishedTemplate: {
        },
        publishedTemplateEditData: {
          title: '',
          description: '',
        },
        templateInfoMode: {},
        templateInfoMouseAction: {},
        templateInfoCurrentEditKey: null,
        templateCategories: [],
        metaApprovalProcesses: [],
        publishedTemplatePermissionSettingDialogVisible: false,
        publishedTemplateAccessors: [],
        permissionAccessUidList: [],
        permissionAccessOrganizationIdList: [],
        organization_permission: {},
        user_permission: {},
        publishedData: null,
        fileStatus: -1, // -1：无权限做任何操作 0:准备发布 2:审核发布 3：审核完毕修改设置,4:审核通过
        rejectDialogVisible: false,
        rejectReason: '',
        canUpdate: false,
        Auditings: [],
        initCategoryStatus: 0,
        useScaler: false,
        editTitle: false,
        notfound:false,
        allTagsData:[],
        tags: [],
        tagEditMode: false,
        tagCompositionInputPlaceholder: ''
      };
    },
    computed: {
      getMembers(){

        return this.publishedTemplateAccessors.map((item)=>{
          return{
            managerId:item.objectId,
            managerType:item.type=='organization'?1:0,
            userName:item.objectName,
            headImgUrl:item.headImgUrl

          }

        })
      }

    },
    mounted() {
      window.onresize = () => {
        this.settingHeight = document.body.clientHeight - 242;
      };
      this.init();
    },
    unmounted() {
    },
    watch: {
    },
    methods: {
      handleExitBtnClicked: function () {
        //拿metaListTable组件保存的路由数据
        let query =  sessionStorage.getItem('files');
        this.$router.push({name:'files',query: query ? JSON.parse(query) : {}});
      },
      handelEditorMessages: function (msg) {

      },
      editorContentChanged: function () {

      },
      editorInitReady: function () {

      },
      getSetting: function () {
        return "";
      },
      init: function () {
        this.getAllTags();
        this.teamId = this.userInfo.mainSiteTeamId;
        getTeamMetaApprovalProcesses().then(res => {
          console.log('getTeamMetaApprovalProcesses   res:', res);
          if (res.data.code === 0) {
            this.metaApprovalProcesses = res.data.data;
            this.setPublishedTemplateDefaultData();
          }
        }).catch(err => {
          console.log('getTeamMetaApprovalProcesses   err:', err);
        });

        getTeamPublishedTemplateCategories(this.userInfo.mainSiteTeamId).then(res => {
          console.log('getTeamPublishedTemplateCategories   res:', res);
          this.templateCategories = res.data;
          this.initCategoryStatus += 1;
          this.setPublishedTemplateDefaultData();
        }).catch(err => {
          console.log('getTeamPublishedTemplateCategories   err:', err);
        });
        if (this.isNotNull(this.templateId)) {
          this.renderPublishTemplateSetting();
        }else if (this.isNotNull(this.fileId)) {
          this.canUpdate = true;
          getTemplateFileMeta(this.fileId).then(res => {
            console.log('getFileTemplatePublish  res:', res);
            if (res.data.code === 0) {
              if (res.data.data.published) {
                this.templateId = res.data.data.publishedTemplate.id;
                this.renderPublishTemplateSetting();
              } else {
                this.fileStatus = 0;
                getDefaultTemplateSetting().then(res=>{
                  console.log('getDefaultTemplateSetting res === ', res);
                  if(res.data.code==0){
                    this.permissionAccessUidList = res.data.data.uids;
                    this.permissionAccessOrganizationIdList = res.data.data.orgIds;
                    this.setPublishedTemplateDefaultData();
                    this.setPublishedTemplateAccessors(res.data.data);
                  }
                })
              }
              this.initCategoryStatus += 1;
            } else if (res.data.code === 404) {
              this.metaMessage['error']('资源未找到');
              this.notfound=true;
            } else {
              this.metaMessage['error']('获取资源失败 msg:' + res.data.msg);
              this.fileStatus = 0;
              this.notfound=true;
            }
          }).catch(err => {
            console.log('getFileTemplatePublish  err:', err);
            this.notfound=true;
          })
          this.initData(this.fileId);
        }

      },

      renderPublishTemplateSetting: function () {
        getTemplateSettings(this.templateId).then(res => {
          console.log('getTemplateSettings  res:', res);
          if (res.data.code === 0) {
            this.publishedData = res.data.data;
            this.initPublishedData(this.publishedData);
            this.fileId = this.publishedTemplate.fileId;
            this.initData(this.publishedTemplate.fileId);
            this.initCategoryStatus += 1;
          } else if (res.data.code === 404) {
            this.metaMessage['error']('资源未找到');
            this.notfound=true;
            //this.goContractHome();
          } else {
            this.metaMessage['error']('获取资源失败 msg:' + res.data.msg);
            this.fileStatus = -1;
            this.notfound=true;
          }
        }).catch(err => {
          console.log('getTemplateSettings  err:', err);
          this.notfound=true;
        })
      },
      setPublishedTemplateDefaultData: function () {
        if (!this.isNotNull(this.publishedTemplateEditData.title) && this.isNotNull(this.$route.query.title)) {
          this.publishedTemplateEditData.title = this.$route.query.title;
          this.publishedTemplate.title = this.$route.query.title;
        }
        // 'categoryId', 'metaApprovalProcessId'
        if (!this.isNotNull(this.publishedTemplate.categoryId) && this.templateCategories.length > 0) {
          this.publishedTemplateEditData.categoryId = this.templateCategories[0].id;
          this.publishedTemplate.categoryId = this.templateCategories[0].id;
        }
        if (!this.isNotNull(this.publishedTemplate.metaApprovalProcessId) && this.metaApprovalProcesses.length > 0) {
          this.publishedTemplateEditData.metaApprovalProcessId = this.metaApprovalProcesses[0].id;
          this.publishedTemplate.metaApprovalProcessId = this.metaApprovalProcesses[0].id;
        }
      },
      initData: function (fileId) {
        getMetaProData(fileId).then(res => {
          const {code, data} = res.data;
          if (code === 0) {
            let docContent = data;
            this.docType = docContent.type
            this.docContent = this.fixTemplateOldData(docContent.type, docContent);
            console.log("this.docContent === ", this.docContent);
            this.initDataSuccess = true;
          } else {
            this.initDataSuccess = false;
            if (code === 403) {
              // 没权限
              window.location.href = config.baseUrl;
            }
          }
        }).catch(err => {
          console.error("getTemplateFileMetaProData  err:", err);
          this.initDataSuccess = false;
        })
      },
      initPublishedData: function () {
        if (this.publishedData != null) {
          console.log('initPublishedData  this.publishedData === ', this.publishedData)
          this.publishedTemplate = this.publishedData.publishedTemplate;
          this.publishedTemplateEditData = JSON.parse(JSON.stringify(this.publishedTemplate));
          // 初始化权限
          this.permissionAccessUidList = this.publishedData.entityAccessibilityDTO.uids;
          this.permissionAccessOrganizationIdList = this.publishedData.entityAccessibilityDTO.orgIds;

          this.tags = this.publishedData.tagDTOs;

          if (this.publishedTemplate.approvalStatus === 0) {
            // 未审核
            if (this.publishedData.approver === true) {
              // 审核人员可通过或拒绝
              this.fileStatus = 2;
            } else {
              // 创建者可继续修改
              this.fileStatus = 3;
            }
          } else if (this.publishedTemplate.approvalStatus === 2) {
            // 审核拒绝
            if (this.publishedData.approver === true) {
              // 审核人员不能做任何操作
              this.fileStatus = -1;
            } else {
              // 创建者可修改重新发布
              this.fileStatus = 5;
            }
          } else {
            this.fileStatus = 4;
          }
          console.log('initPublishedData this.fileStatus === ', this.fileStatus);
          this.canUpdate = this.publishedTemplateCanUpdate();
          this.setPublishedTemplateAccessors(this.publishedData.entityAccessibilityDTO);
        }
      },
      setPublishedTemplateAccessors: function (entityAccessibilityDTO) {
        colorLog.blue('entityAccessibilityDTO',entityAccessibilityDTO)
        this.publishedTemplateAccessors = [];
        for (const organization of entityAccessibilityDTO.organizations) {
          this.publishedTemplateAccessors.push({
            type: 'organization',
            objectId: organization.id,
            objectName: organization.name
          });
        }

        for (const user of entityAccessibilityDTO.userDTOs) {
          this.publishedTemplateAccessors.push({
            type: 'user',
            objectId: user.uid,
            objectName: user.userName,
            headImgUrl:user.headImgUrl
          });
        }
      },
      fixTemplateOldData:function (docType,docContent) {
        if (docType !== 'template') {
          return docContent;
        }
        if(!docContent.variableRects){
          return docContent;
        }
        Object.keys(docContent.variableRects).forEach((key)=>{
          let rect = docContent.variableRects[key];
          if (!rect || VARIABLE_TYPE_OPTIONS.MULTIPLE_OPTION.value !== rect.type) {
            return;
          }
          if (typeof rect.standardAnswer === 'string') {
            rect.standardAnswer = [rect.standardAnswer]
          }
        })
        return docContent;
      },
      onTemplateInfoMouseenter: function (key) {
        if (!this.canUpdate) return;
        if (this.templateInfoMode[key] === "edit") return;
        this.templateInfoMode[key] = "read";
        this.templateInfoMouseAction[key] = "enter";
      },
      onTemplateInfoMouseleave: function (key) {
        if (!this.canUpdate) return;
        if (this.templateInfoMode[key] === "edit") return;
        this.templateInfoMouseAction[key] = "leave";

        if (this.templateInfoMouseAction[key] === "enter") return;
        this.templateInfoMode[key] = "undefined";
        this.templateInfoMouseAction[key] = "leave";
      },
      editTemplateInfo: function (key) {
        this.changeTemplateInfoCanceled(this.templateInfoCurrentEditKey);
        this.templateInfoCurrentEditKey = key;
        this.templateInfoMouseAction[key] = "leave";
        this.templateInfoMode[key] = "edit";
      },
      changeTemplateInfoCanceled: function (key) {
        if (!key) return;
        this.templateInfoMode[key] = "undefined";
        this.templateInfoCurrentEditKey = null;
      },
      getInputValue: function (key) {
        if (this.isNotNull(this.publishedTemplate[key])) {
          return "" + this.publishedTemplate[key];
        }
        return '';
      },
      saveTemplateInfo: function (key) {
        this.templateInfoMode[key] = "undefined";
        this.templateInfoCurrentEditKey = null;
        this.onTemplateInfoChanged(key);
      },
      onCategoryChange: function (value) {
        this.publishedTemplateEditData['categoryId'] = value;
        this.onTemplateInfoChanged('categoryId');
      },
      onTemplateInfoChanged: function (key) {
        let canUpdate = this.publishedTemplateCanUpdate();
        console.log('onTemplateInfoChanged  key:' + key + '  canUpdate === ', canUpdate);
        if (canUpdate) {
          this.publishedTemplate[key] = this.publishedTemplateEditData[key];
          if (this.templateId) {
            updatePublishedTemplate(this.templateId, this.publishedTemplate).then(res => {
              console.log('updatePublishedTemplate  res === ', res);
              let changedAttributeName = '';
              if (key === 'categoryId') {
                changedAttributeName = '模板分类';
              } else if (key === 'title') {
                changedAttributeName = '模板名称';
              } else if (key === 'metaApprovalProcessId') {
                changedAttributeName = '审批流';
              } else if (key === 'description') {
                changedAttributeName = '模板描述';
              }
              ElMessage.success('修改' + changedAttributeName + '成功');
            }).catch(err => {
              console.error('updatePublishedTemplate  err:', err);
              this.metaMessage['error']('修改模板文件失败');
            })
          }
        }
      },
      getTemplateInfoSelectionOptions: function (key) {
        if (key === 'categoryId') return this.templateCategories;
        if (key === 'metaApprovalProcessId') return this.metaApprovalProcesses;
        return [];
      },
      savePublishedTemplatePermission: function (visibleObjects) {
        console.log('savePublishedTemplatePermission  visibleObjects === ', visibleObjects);
        let canUpdate = this.publishedTemplateCanUpdate();
        console.log('savePublishedTemplatePermission canUpdate === ', canUpdate);
        if (canUpdate) {
          this.publishedTemplateAccessors = visibleObjects;
          this.permissionAccessOrganizationIdList = [];
          this.permissionAccessUidList = [];
          for(let visibleObject of visibleObjects){
            let {type,objectId,permission} = visibleObject;
            if(type === 'user'){
              this.permissionAccessUidList.push(objectId);
            }else if(type === 'organization'){
              this.permissionAccessOrganizationIdList.push(objectId);
            }
          }
          const entityAccessibilityDTO = {
            entityId: this.templateId,
            entityType:0,
            uids:this.permissionAccessUidList,
            orgIds:this.permissionAccessOrganizationIdList
          }
          if (this.templateId) {
            updateTemplatePublishPermissionAccess(this.templateId, entityAccessibilityDTO).then(res => {
              globalTagClick('template-publish-permission-save');
              console.log('updateTemplatePublishPermissionAccess  res === ', res);
            }).catch(err => {
              console.error('updateTemplatePublishPermissionAccess  err:', err);
              this.metaMessage['error']('修改权限失败');
            })
          }
        }
      },
      publishedTemplateCanUpdate: function () {
        let canUpdate = true;
        if (this.templateId && this.publishedTemplate.approvalStatus === 1) {
          // 审核通过，只有管理员才能修改
          canUpdate = this.publishedData.approver;
        }

        return canUpdate;
      },
      publishSubmit: function () {
        if (!this.checkPublishedTemplate()) {
          return;
        }
        this.loadingInstance = ElLoading.service({
          fullscreen: true,
          body: true,
          lock: true,
          spinner: 'el-icon-loading'
        });

        const putData = this.structurePublishTemplate();
        putData.publishedTemplate.teamId = this.teamId;

        putTemplatePublish(putData).then(res => {
          console.log('putTemplatePublish  res === ', res);
          this.loadingInstance.close();
          this.loadingInstance = null;
          if (res.data.code === 0) {
            globalTagClick('template-publish-publish')
            this.metaMessage['info']('发布成功');
            // this.fileStatus = 3;
            this.goContractHome();
          } else {
            this.metaMessage['error'](res.data.msg);
          }
        }).catch(err => {
          if (err.response) {
            this.metaMessage['error']('发布失败，' + err.response.data.message);
          } else {
            this.metaMessage['error']('发布失败');
          }
          this.loadingInstance.close();
          this.loadingInstance = null;
        })
      },
      checkPublishedTemplate: function () {
        console.log('checkPublishedTemplate  this.publishedTemplate === ', this.publishedTemplate)
        if (!this.isNotNull(this.publishedTemplate.categoryId)) {
          this.metaMessage['error']('请选择合同分类');
          return false;
        }

        if (!this.isNotNull(this.publishedTemplate.title)) {
          this.metaMessage['error']('请输入标题');
          return false;
        }

        return true;
      },
      structurePublishTemplate: function () {
        const entityAccessibilityDTO = {
          entityId: this.templateId,
          entityType:0,
          uids:this.permissionAccessUidList,
          orgIds:this.permissionAccessOrganizationIdList
        }
        const tagIds = this.tags.map(tag => tag.id);

        return {
          publishedTemplate: this.publishedTemplate,
          entityAccessibilityDTO: entityAccessibilityDTO,
          sourceFileId:this.fileId,
          tagIds: tagIds
        }
      },
      saveTemplateSetting: function () {
        if (!this.checkPublishedTemplate()) {
          return;
        }

        const publishedTemplateDTO = this.structurePublishTemplate();
        this.openLoading();
        this.loadingCount = 1;
        if (this.publishedTemplateTitle !== this.oldPublishedTemplate.title
                || this.publishedTemplateDescription !== this.oldPublishedTemplate.description
                || publishedTemplateDTO.publishedTemplate.categoryId !== this.oldPublishedTemplate.categoryId) {
          this.loadingCount += 1;
          updatePublishedTemplate(this.templateId, publishedTemplateDTO.publishedTemplate).then(res => {
            console.log('updatePublishedTemplate  res === ', res);
            this.loadingCount -= 1;
            if (this.loadingCount === 0) {
              this.metaMessage['info']('修改成功');
              this.closeLoading();
              this.goContractHome();
            }
          }).catch(err => {
            console.error('updatePublishedTemplate  err:', err);
            this.metaMessage['error']('修改模板文件失败');
            this.closeLoading();
          })
        }

        updateTemplatePublishPermissionAccess(this.templateId, publishedTemplateDTO.entityAccessibilityDTO).then(res => {
          console.log('updateTemplatePublishPermissionAccess  res === ', res);
          this.loadingCount -= 1;
          if (this.loadingCount === 0) {
            this.metaMessage['info']('修改成功');
            this.closeLoading();
            this.goContractHome();
          }
        }).catch(err => {
          console.error('updateTemplatePublishPermissionAccess  err:', err);
          this.metaMessage['error']('修改权限失败');
          this.closeLoading();
        })
      },
      approvalPassed: function () {
        this.openLoading();
        passPublishedTemplate(this.templateId).then(res => {
          this.metaMessage['info']('已通过');
          this.fileStatus = -1;
          this.closeLoading();
          this.goContractHome();
        }).catch(err => {
          console.error('passPublishedTemplate  err:', err);
          this.metaMessage['error']('请求失败');
          this.closeLoading();
        })
      },
      approvalReject: function () {
        if (!this.isNotNull(this.rejectReason)) {
          this.metaMessage['info']('请输入驳回理由');
          return;
        }

        this.openLoading();
        rejectPublishedTemplate(this.templateId, {reason: this.rejectReason}).then(res => {
          globalTagClick('template-publish-reject',{templateId:this.templateId,reason:this.rejectReason});
          this.metaMessage['info']('已拒绝');
          this.fileStatus = -1;
          this.rejectDialogVisible = false;
          this.closeLoading();
          this.goContractHome();
        }).catch(err => {
          console.error('rejectPublishedTemplate  err:', err);
          this.metaMessage['error']('请求失败');
          this.closeLoading();
        })
      },
      rePublishSubmit: function () {
        this.openLoading();
        rePublishPublishedTemplate(this.templateId).then(res => {
          globalTagClick('template-publish-republish',{templateId:this.templateId});
          this.metaMessage['info']('重新发布成功');
          this.fileStatus = 3;
          this.closeLoading();
          this.goContractHome();
        }).catch(err => {
          this.metaMessage['error']('请求失败');
          this.closeLoading();
        })
      },
      goContractHome: function() {
        globalTagClick('template-publish-backward',{fileId:this.fileId});

        this.$router.push({
          name: 'files'
        })
      },
      editPublishedTemplate: function () {
        globalTagClick('template-publish-edit',{fileId:this.fileId});
        this.$router.push({
          name: 'doc',
          query: {fileId: this.fileId}
        })
      },

      // :class="{'stage-gray': publishedTemplate == null,
      // 'bg-dandelion': publishedTemplate != null && publishedTemplate.approvalStatus === 0,
      //         'bg-mint': publishedTemplate != null && publishedTemplate.approvalStatus === 1,
      //         'bg-mona-lisa': publishedTemplate != null && publishedTemplate.approvalStatus === 2}
      getTemplateStatusText: function () {
        if (this.isNotNull(this.templateId)) {
          if (this.publishedTemplate.approvalStatus === 0) {
            return '模板审批中'
          } else if (this.publishedTemplate.approvalStatus === 1) {
            return '模板'
          } else if (this.publishedTemplate.approvalStatus === 2) {
            return '模板审批驳回'
          }
          return '';
        } else {
          return '模板草稿';
        }
      },
      onRenameBtnClick: function () {
        this.editTemplateInfo('title');
      },
      deletePublishedTemplate: function () {
        if (this.isNotNull(this.templateId)) {
          // 已发布的模板，只有管理员才有权限删除
          if (this.publishedData.approver === true) {
            // 审核通过
            deletePublishedTemplate(this.templateId).then(res => {
              console.log('deletePublishedTemplate  res:', res);
              if (res.data.code === 0) {
                this.goContractHome();
                globalTagClick('template-publish-delete')
              } else {
                this.metaMessage['error']('删除失败:' + res.msg);
              }
            }).catch(err => {
              console.error('deletePublishedTemplate err:', err);
              this.metaMessage['error']('删除失败');
            })
          } else {
            this.metaMessage['info']('无权限');
          }
        } else {
          deleteTemplateFile(this.fileId).then(res => {
            console.log('deleteFilecache  res:', res);
            if (res.data.code === 0) {
              this.goContractHome();
            } else {
              this.metaMessage['error']('删除失败:' + res.msg);
            }
          }).catch(err => {
            console.error('deleteFilecache err:', err);
            this.metaMessage['error']('删除失败');
          })
        }
      },

      isNotNull: function (obj) {
        return obj !== null && obj !== undefined && (obj+"").length > 0;
      },
      toolTabClick: function (tab) {
        if (tab.paneName === "setting") {
        } else if (tab.paneName === "record") {
          getTemplateAuditing(this.fileId).then(res => {
            console.log('getTemplateAuditing  res:', res);
            if (res.data.code === 0) {
              this.Auditings = res.data.data;
            }
          }).catch(err => {

          })
        }
      },
      Dataformat :function(data){
        const dt = new Date(data)
        const y = dt.getFullYear()
        const m = (dt.getMonth() + 1 + '').padStart(2,0)
        const d = (dt.getDate() + '').padStart(2,0)
        return m+"/"+d+"/"+y;
      },
      HourMinuteformat :function (data) {
        let str = data;
        return str.slice(11);
      },
      generateUUID: function() {
        function S4() {
          return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        }
        return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
      },
      FileBtnHover() {
        if (this.menuActive) {
          this.FileMenuVisible = true;
        }
      },
      settingBtnHover() {
        if (this.menuActive) {
          this.settingMenuVisible = true;
        }
      },
      getSelection() {
        return window.getSelection();
      },
      openLoading: function() {
        this.loadingInstance = ElLoading.service({
          fullscreen: true,
          body: true,
          lock: true,
          spinner: 'el-icon-loading'
        });
      },
      closeLoading: function () {
        this.loadingInstance.close();
        this.loadingInstance = null;
      },
      createPublishedTemplateCategory: function (value) {
        console.log('createPublishedTemplateCategory  value === ', value);
        const category = {
          teamId: this.teamId,
          name: value
        }
        addPublishedTemplateCategory(category).then(res => {
          console.log('addPublishedTemplateCategory  res:', res);
          if (res.data.code === 0) {
            console.log('category-selection-creating === ', this.$refs['category-selection-creating']);
            this.$refs['category-selection-creating'][0].clearInput();
            this.templateCategories.push(res.data.data)
          } else {
            this.metaMessage['error']('添加失败,' + res.data.msg);
          }
        }).catch(err => {
          console.log('addPublishedTemplateCategory  err:', err);
          this.metaMessage['error']('添加失败');
        })
      },
      deleteCategory: function (data, index) {
        console.log('deleteCategory data:', data);
        deletePublishedTemplateCategory(this.teamId, data.id).then(res => {
          console.log('deletePublishedTemplateCategory  res:', res);
          if (res.data.code === 0) {
            const deleteSelectedValue = this.templateCategories[index].id === this.publishedTemplateEditData['categoryId'];
            console.log('deleteSelectedValue === ', deleteSelectedValue)
            this.templateCategories.splice(index, 1);
            if (deleteSelectedValue) {
              if (this.templateCategories.length > 0) {
                const firstCategory = this.templateCategories[0];
                this.publishedTemplateEditData.categoryId = firstCategory.id;
                this.publishedTemplate.categoryId = firstCategory.id;
                this.$refs['category-selection-creating'][0].setInputValue(firstCategory.name);
              } else {
                this.publishedTemplateEditData.categoryId = null;
                this.publishedTemplate.categoryId = null;
                this.$refs['category-selection-creating'][0].setInputValue("");
              }
            }
          } else {
            this.metaMessage['error']('删除失败 msg:' + res.data.msg);
          }
        }).catch(err => {
          this.metaMessage['error']('删除失败，此模板分类已被使用');
          console.error('deletePublishedTemplateCategory  err:', err);
        })
      },
      onTitleClick: function () {
        if (this.canUpdate) {
          this.editTitle = true;
          this.$refs.input.focus();
        }
        console.log('onTitleClick title ==== ', this.publishedTemplateEditData['title'])
      },
      handleKeydown(e){
        if(e.keyCode === 13){
          // 按下回车
          this.$refs.input.blur();
          e.preventDefault();
          e.stopPropagation();
          return;
        }
        if(e.keyCode == 27){
          //按下esc
          this.disableEdit = true;
          this.$refs.input.blur();
          this.disableEdit = false;

          e.preventDefault();
          e.stopPropagation();
          return;
        }
        if(e.code == 'Backspace'){
          //按下backspace
          this.useScaler = true;
          return;
        }

      },
      handleCompositionstart(e){
        this.compositionStart = true;
      },
      handleCompositionend(e){
        this.upgradeWidthWithScaler();
        this.compositionStart = false;
      },
      upgradeWidthWithScaler(){
        let publishedTemplateTitle = this.$refs.publishedTemplateTitle;
        let value = this.$refs.input.input.value
        publishedTemplateTitle.innerText = value;
      },
      updateTitle: function (val) {
        this.editTitle = false;
        this.publishedTemplateEditData['title'] = val;
        this.onTemplateInfoChanged('title');
      },
      onPermissionClicked: function () {
        if (this.canUpdate) {
          this.$refs.permissionWindow.openWindow();
        }
      },
      handleTagCompositionModeChanged(mode, popperVisible){
        this.tagEditMode = popperVisible;
        if (mode === 1) {
          this.tagCompositionInputPlaceholder = '搜索待办...';
        } else {
          this.tagCompositionInputPlaceholder = '';
        }
      },
      handleTagCompositionTagResolved: function (tagData) {
        console.log('handleTagCompositionTagResolved  tagData:', tagData)
      },
      clickTagsEdit(){
        this.tagEditMode = true;
        this.$refs.tagComposition.api.changeMode();
      },
      getAllTags(){
        getTags().then(res => {
          this.allTagsData = res.data.data;
        }).catch(err => {
          console.error("getAllTags  err:", err);
        });
      },
      publishedTemplateAddTag: function (name ,type) {
        let canUpdate = this.publishedTemplateCanUpdate();
        if (canUpdate) {
          if (this.templateId) {
            return addPublishedTemplateTag(this.templateId, {name: name, type: type});
          } else {
            return addTag({name: name, type: type});
          }
        }
      },
      publishedTemplateDeleteTag: function (tagId) {
        let canUpdate = this.publishedTemplateCanUpdate();
        if (canUpdate) {
          if (this.templateId) {
            return deletePublishedTemplateTag(this.templateId, tagId);
          } else {
            return Promise.resolve({
              data:{code:0}
            });
          }
        }
      },
      publishedTemplateCompleteTag: function (tagId, data) {
        return Promise.resolve({
          data:{code:0}
        });
      },
      publishedTemplateCancelTagCompleteStatus: function (tagId) {
        return Promise.resolve({
          data:{code:0}
        });
      }

    },
    beforeUpdate() {
      this.versionSeries = [];
    },



  };
</script>

<style scoped lang="scss">
  .draft-operation-item{
    text-align: left;
    cursor: pointer;
    font-weight: bold;

    height: 20px;
    margin: 10px;
    font-size: .875rem;
    white-space: nowrap;

  }
  .draft-operation-pass{
    color:var(--lightBlue);
    &:hover{
      color:var(--darkBlue);
    }
  }
  .draft-operation-decline{
    color:rgb(223,60,47);
    &:hover{
      color: rgb(167, 45, 35);
    }
  }
  .el-button{
    font-weight: bold;
  }
</style>

<style scoped>
  @import "../assets/css/contractHeader.css";


  .contractViewUpperSection {
    padding-top: 10px;
    padding-left: 20px;
    border-bottom: solid 1px #eee;
    background: white;
  }

  .agreement-container {
    height: calc(100% - var(--contractEditHeaderHeight));


    position: relative;
    width: 100%;

  }

  .main-container {


    /*display: flex;*/
    /*flex-direction: column;*/
    width: 100%;
  }

  .content-container {
    display: flex;
    justify-content: center;
    background-color: #f8f9fa;
  }

  .preview-container {
    background: white;
    padding: 0px 0px;
    box-shadow: rgb(60 64 67 / 15%) 0 0px 3px 1px;
    /*display: flex;*/
    /*flex-direction: column;*/
    margin-right: 3px;
    width: 816px;
  }

  .preview-container >>> .pdfViewer {
    width: 816px;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .buttonGroup {
    height: 32px;
    line-height: 32px;
    margin-bottom: 10px;
  }

  .sticky {
    position: sticky;
    top: 28px;
    z-index: 11;
    background-color: inherit;
  }

  .tool-container {
    display: inline-block;
    position: relative;
    width: 446px;
    box-sizing: content-box;
    background-color: inherit;
    padding: 0 14px 0 28px;
  }
  .contract_versions_info{
    padding: 0 5px;
  }

  .contract_version_container{
    transition: box-shadow .5s;
    background: #fff;
    padding: 5px;
    box-shadow: 0px 1px 3px 1px #eee;
    border-radius: 2px;
    cursor: pointer;
    width: 100%;
  }
  .contract_version_small{
    margin: 0 0 0 15px;
    display: none;
  }
  .contract_version_small .contract_version_container{
    margin-top: 5px;
  }
  .version-series:not(:first-child){
    margin-top: 5px;
  }
  .version-series.expanded .contract_version_small{
    display: block;
  }

  .expand-button button{
    font-size: 12px;
    border: 1px solid #dcdfe6;
    border-radius:3px;
    padding:7px 15px;
    line-height:1;
    color:#606266;
    background:white;
    cursor:pointer;
  }

  .version-series.expanded .expand-button button{
    border-color:var(--lightBlue);
    color:var(--lightBlue);
    background-color: var(--hoverLightBlue);
  }


  .contract_version_container:hover{
    box-shadow: 0px 1px 3px 3px #e3e0e0;
  }

  .contract_version_item {
    display: flex;
    align-content: center;
    align-items: center;
    padding-right: 2px;
    text-align: left;
  }

  .version-series > .contract_version_container .version_info
  {
    border-left: solid 1px #eee;
  }

  .version_info {
    display: flex;
    align-content: center;
    align-items: center;
    padding-right: 10px;
    margin-left: 10px;
    padding-left: 25px;
    font-weight: bold;
    font-size: 14px;
  }

  .contract_version_name button {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 250px;
    border: none;
    text-align: left;
  }

  .operation-btn:not(:first-of-type){
    margin-left:5px;
  }

  .question-answer-wrapper {
    text-align: left;
  }

  .answer {
  }

  .question-answer-item {
    padding: 5px 0;
  }

  .contract-attachment {
    position: absolute;
    top: 26px;
    left: 0;
    background: white;
    z-index: 2;
    padding: 10px;
    box-shadow: rgb(60 64 67 / 15%) 0px 2px 6px 2px;
  }

  .tool-container >>> .el-tabs .el-tabs__nav .el-tabs__item {
    padding: 3.5px 7px;
    border-radius: 3.5px;
    color: #999;
    height: auto;
    line-height: unset;
  }

  .tool-container >>> .el-tabs .el-tabs__nav .el-tabs__item:nth-child(n+3) {
    margin-left: 8px;
  }

  .tool-container >>> .el-tabs .el-tabs__nav .el-tabs__item:not(.is-active):hover {
    background: #EEE !important;
  }

  .tool-container >>> .el-tabs .el-tabs__nav .el-tabs__item.is-active {
    background: #e4ebff;
    color: #202124;
    line-height: unset;
    height: auto;
  }

  .tool-container >>> .el-tabs .el-tabs__nav-wrap::after {
    display: none;
  }

  .tool-container >>> .el-tabs .el-tabs__active-bar {
    display: none;
  }

  .list {
    list-style: none;
  }

  .flex {
    display: flex;
    align-items: center;
  }
  .contract-creator{
    color:#aaa;
    font-weight: normal;
  }
  .contract-createTime{
    font-weight:600;
  }

  .contract-name {
    color: var(--lightBlue) !important;
    cursor: pointer;
  }

  .contract-name:hover {
    color: var(--darkBlue)!important;
  }

  .contract_version_name {
    margin: 10px 0;
    flex: 1;
    white-space: nowrap;

    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
  }
  .versionSelector-tip{
    color:#aaa;
  }
  .diff-tool{
    align-items: stretch;
  }
  .diff-tool >>> .meta-level-selector{
    width: calc(50% - 42.5px);
    margin-left:5px;
  }
  .diff-tool >>> .meta-level-selector:last-of-type{
    margin-right: 5px;
  }
  .diff-tool >>> .meta-level-selector-bar{
    height:100%;
  }
  .diff-tool >>> .selector-bulletin{

  }
  .diff-selectTip{
    display: inline-block;
    padding-right: 5px;
  }

  .pdf-tool-panel {
    position: sticky;
    top: 28px;
    z-index: 9;
    padding: 10px;
    width: 816px;
    text-align: left;
    background: #fff;
    min-height: 52px;
    display: flex;
    box-shadow: inset rgb(60 64 67 / 15%) 0px 1px 1px 0px;
  }

  .preview-container >>> .el-table td {
    overflow: unset;
    border-bottom: 0;
  }

  .preview-container >>> .el-table::before {
    height: 0;
  }

  .preview-container >>> .page {
    border-image: none;

    border: 0;
    /*width: 100% !important;*/
    margin-bottom: 0;
    border-bottom: solid 1px rgb(60 64 67 / 15%);
    /*box-shadow: rgb(60 64 67 / 15%) 1px 1px 3px 1px;*/
  }

  /*.preview-container >>> .canvasWrapper {*/
  /*  width: 816px !important;*/
  /*}*/

  /*.preview-container >>> .canvasWrapper canvas {*/
  /*  width: 816px !important;*/
  /*}*/

  /*.preview-container >>> .textLayer {*/
  /*  width: 816px !important;*/
  /*}*/

  .el-dropdown__popper >>> .el-dropdown-menu__item:focus,
  .el-dropdown-menu__item:not(.is-disabled):hover {
    background: #dbf2ff;
    color: #63a4ff;
  }

  .content-container .preview-container .pdf-tool-panel >>> .pop-over .pop-list {
    padding: 0;
  }

  .tags {
    margin-left: 14px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    border-bottom: solid 1px #999;
    background: #f4f4f4;
  }

  .tags:hover {
    border-color: #5280ff;
  }

  .tags:active {
    border-color: #5280ff;
  }

  .unselectable {
    cursor: default;
    color: #dddddd;
  }

  .unselectable:hover {
    background: white !important;
  }

  .stage-order {
    width: 28px;
    height: 28px;
    text-align: center;
    line-height: 28px;
    border-radius: 20px;
    color: white;
    overflow: hidden;
    position: relative;

    flex-shrink: 0;
    margin-right: -5px;
    border: solid 1px white;
  }

  .stage-index {
    padding-right: 10px;
    border-right: solid 1px #ddd;
    margin-right: 10px;
    flex-shrink: 0;

  }

  .approval-stage {
    padding: 10px 10px;
    border-bottom: solid 1px #f3f3f3;
  }

  .stage-badge {
    background: #6dce46;
    position: absolute;
    left: -7px;
    top: -5px;
    border-radius: 10px;
    height: 20px;
    width: 20px;
    line-height: 24px;
    text-align: center;
    box-shadow: rgb(60 64 67 / 15%) 1px 1px 3px 1px;
    color: white;
  }

  .circle {
    border-radius: 10px;
    height: 20px;
    width: 20px;
    line-height: 24px;
    text-align: center;
    color: white;
  }

  .pass {
    background: #6dce46;
  }

  .refuse {
    background: #ff4646;
  }

  .pass-light {
    background: #f9fff1;
  }

  .refuse-light {
    background: #fdf4f4;
  }

  .active-stage {
    background: #dff1ff;
  }

  #pdf-container {
    height: 100%;
    width: 100%;
    position: absolute;
    overflow-y: auto;
    overflow-x: hidden;
  }

  @media screen and (max-width: 1300px) {
    .content-container {
      justify-content: flex-end;
      width: fit-content;
    }

    .main-container{
      width: fit-content;
    }

    #pdf-container{
      overflow-x: scroll;

    }

    /* warning 这里是浏览器宽度小于1300的样式 */
  }

  /* 概览 ---------------------------- start --------------------------------*/

  .contract_relation_info {
    text-align: left;
  }

  .relationMetaInfo {
    margin-left: 0;
    display: flex;
    align-items: baseline;
  }

  .relationInfo {
    margin-left: 0;
  }

  .edit-btn {
    margin-left: 10px;
    position: absolute;
    right: 0;
  }

  .relation_img {
    display: inline-block;
    align-self: center;
    width: 20px;
    height: 20px;
    cursor: pointer;
    vertical-align: top;
  }

  .relation-add-row-container {
    /*width: 16px;*/
    /*height: 16px;*/
    /*display: inline-block;*/
    position: relative;
    /*top: -10px;*/
    cursor: pointer;
    display: flex;
    width: 100%;
  }

  .relation-add-row-img {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  .relation-key-gray {
    color: #c0c4cc;
  }

  .relation-more {
    margin-left: 5px;
  }

  .ml5 {
    margin-left: 5px;
  }

  .change-overview-cancel-btn {
    width: 60px;
    /*height: 30px;*/
    /*line-height: 30px;*/
    text-align: center;
    background-color: white;
    border: 1px #dcdfe6 solid;
    border-radius: 4px;
    /*box-shadow: 0 3px 5px -3px #404452;*/
    margin-left: 2px;
    cursor: pointer;
  }

  .change-overview-confirm-btn {
    width: 60px;
    /*height: 30px;*/
    /*line-height: 30px;*/
    text-align: center;
    background-color: #5280ff;
    color: white;
    border: 1px #dcdfe6 solid;
    border-radius: 4px;
    /*box-shadow: 0 3px 5px -3px #404452;*/
    margin-left: 2px;
    cursor: pointer;
  }

  .ptc {
    /*text-align: center !important;*/
    margin-bottom: 20px;
  }

  /* 概览 ---------------------------- end --------------------------------*/

</style>

<style lang="scss" scoped>
  @import url("../assets/css/alert.css");
  @import url("../assets/css/comment.css");
  .pdf-inline-tool {
    background-color: #fff;
    border: 1px solid #eaeaea;
    box-shadow: 0 3px 15px -3px rgba(13, 20, 33, 0.13);
    border-radius: 4px;
    padding: 5px;

    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      padding: 0 5px;
    }
  }
  .disable{
    cursor:not-allowed!important;
  }



  /* 合同问题样式 */

  .conclusive-firstArea{
    width: calc(100% - 30px);
    display: flex;
  }
  .conclusive-firstArea-tags{
    text-align: left;
    display: flex;
    align-items: baseline;
    flex-shrink:1;
  }
  .conclusive-firstArea-questionnaire{
    width: 100px;
    flex-shrink:0;
  }
  .conclusive-label-icon{
    width: 18px;
    height: 18px;
    font-size: 18px;
    margin-right: 10px;
    display: inline-block;
  }
  .tag-inputTool-expanded{
    width: unset;
  }
  .conclusive-area:nth-of-type(n+2){
    margin-top:10px;
  }
  .questionnaire-svg{
    color:#000;
  }
  .questionnaire-svg .svg-icon{
    vertical-align: unset;
  }

  .questionnaire-svg--active{
    //color:#5280FF;
  }
  .answerSheet-top{
    display:flex;
    justify-content: space-between;
    padding: 10px 0;
    position: sticky;
    top: 0;
    z-index: 4;
    background: #fff;

  }
  .answerSheet-tip{
    text-align: left;
    font-size: 16px;
    margin: 0;
  }
  .answerSheet-close{
    color:#b7b1b1;
    height: 16px;
    width:16px;
    cursor:pointer;
  }
  .answerSheet-tip::before{
    position: absolute;
    content: '';
    bottom: -29px;
    height: 30px;
    width: 100%;
    background-image: linear-gradient(to bottom, #cccccc2e, transparent);
    opacity: 0;
    transition: .5s opacity;
    pointer-events: none;
  }
  .answerSheet-tip--veil::before{
    opacity: 1;
  }
  .answerSheetContainer{
    position: fixed;
    z-index:11;
    width:630px;
    height: 50vh;
    overflow-y:auto;
    visibility: hidden;
    pointer-events: none ;
    padding:0px 10px 10px 10px;
    box-shadow:0 0 5px 3px #cccccc47;
    background: #fff;
    opacity: 0;
    transition: .5s opacity;
    scroll-width:thin;
  }
  .answerSheetContainer--visible{
    visibility: visible;
    pointer-events: unset ;
    opacity: 1;
  }
  #pane-relation .el-scrollbar{
    padding-right: 10px;
  }
  .tool-container .el-tabs__content{
    padding-left:10px;
  }
  .tags .el-tag__close:hover{
    background: transparent !important;
    color: #d43b3b !important;
  }
  .tags .el-tag .el-icon-close{
    transform: scale(1.2);
  }
  #pane-versions .el-button:hover{
    border: solid 1px var(--lightBlue) !important;
  }
  #pdf-tool-right .el-button:hover,#upload-newVersion:hover{
    border: solid 1px var(--lightBlue) !important;
    color: var(--lightBlue) !important;
  }
  .comments-empty{
    width: 100%;
    text-align: center;
  }
  .comments-empty-img{
    width: 80%;
  }
  .comments-empty-text{
    font-size: 16px;
    font-weight: 400;
    margin: 0;
  }
  .version-index{
    color:var(--lightBlue);
    min-width: 30px;
    font-weight: 600;
    margin-left:5px;
  }
  .pdf-tool-left{
    --pdfToolLeftHeight:32px;
    height: var(--pdfToolLeftHeight);
    display:flex;
    flex: 1;

  }
  #searchToolBtn{
    color:var(--lightBlue);
  }
  #searchToolBtn:hover,.singleBtnTool--active{
    color:var(--darkBlue) !important;
  }
  .pdf-tool-btn{
    display: inline-block;
    height: var(--pdfToolLeftHeight);
    width: var(--pdfToolLeftHeight);
    cursor:pointer;
  }
  .version-option-p{
    margin:0
  }
  .el-select-dropdown__item.selected{
    color:var(--lightBlue) !important;
  }
  .version-small-option{
    height: 34px;
    line-height: 34px;
    cursor:pointer;
  }
  .version-small-option:hover{
    background-color: #f5f7fa;
  }

  .underline {
    /*text-decoration:underline;*/
    border-bottom: 1px solid black;
  }

  .pkh {
    display: inline;
  }

  .el-textarea__inner {
    resize: none;
  }

  .el-textarea__inner:focus {
    outline: none;
    border-color: var(--el-input-focus-border, var(--el-color-primary));
  }

  .el-textarea__inner:hover {
    border-color: var(--el-input-hover-border, var(--el-border-color-hover));
  }

  /* 滚动条框设置 */
  .el-textarea__inner::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
    /*background-color: #c0c4cc;*/
  }

  /* 滚动条的设置 */
  .el-textarea__inner::-webkit-scrollbar-thumb {
    background-color: #c0c4cc;
    border-radius: 5px;
  }
  .conclusive-secondArea{
    display: flex;
    justify-content: flex-start;
  }

  /* 模版发布 -----------------------------------  */
  #editor-content-el-main {
    padding: 0;
    /*52是header高度*/
    overflow: visible;
  }

  #editorjs_container_scroller{
    overflow:visible;
  }
  .previewModeScrollerClass[class]{
    box-shadow: unset;
  }

  .published-template-setting-container {
    overflow-y: auto;
    text-align: left;
  }

  .ptc {
    display: flex;
    align-items: center;
    align-content: center;
    position: relative;
    width: 440px;
  }

  .ptc-key {
    /*width: 120px;*/
    /*!*min-width: 80px;*!*/
    /*!*max-width: 150px;*!*/
    /*font-family: SourceHanSansSC;*/
    /*font-weight: 400;*/
    /*font-size: 14px;*/
    /*color: rgba(138, 138, 138, 1);*/
    /*font-style: normal;*/
    /*letter-spacing: 0;*/
    /*line-height: 30px;*/
    /*text-decoration: none;*/
    /*text-align: left;*/


    min-width: 90px;
    max-width: 150px;
    color: #6a7383;
    font-weight: unset;
  }

  .ptc-value {
    /*display: flex;*/
    /*width: 280px;*/
    /*text-align: left;*/
    /*!*margin-left: 50px;*!*/

    /*!*磨刀样式*!*/
    /*font-family: SourceHanSansSC;*/
    /*font-weight: 400;*/
    /*font-size: 14px;*/
    /*color: rgba(0, 0, 0, 1);*/
    /*font-style: normal;*/
    /*letter-spacing: 0;*/
    /*line-height: 30px;*/
    /*text-decoration: none;*/

    margin-left: 10px;
    color: #404452;
    display: flex;
    width: 280px;
    word-break:break-all;
  }

  .ptc-value-permission {
    margin-left: 10px;
    color: #404452;
    font-weight: 700;
    width: 280px;
    display: flex;
    /*padding: 5px;*/
  }

  .text-m3 {
    /*最多显示三行，超过部分显示...*/
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .permission-item {
    display: inline-block;
    margin-right: 10px;
    margin-top: 5px;
    background-color: rgb(225, 237, 255);
    border-style: none;
    border-color: unset;
    color: rgb(138, 138, 138);
    border-radius: 5px;
    font-size: 14px;
    padding: 2px;
    text-align: center;
    line-height: 29px;
    font-weight: normal;
    font-style: normal;
  }

  .published-template-title {
    font-size: 21px;
    font-weight: 400;
    white-space: nowrap;
  }

  #template-wrap{
    --titleFontSize:21px;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    position: relative;
  }

  .template-title {
    font-size: var(--titleFontSize);
    font-weight: 400;
  }

  #template-wrap >>> #editTitleInput{
    margin: 0;
    font-size: var(--titleFontSize);
    height: 30px;
    line-height: 30px;
    font-weight: 400;
    padding: 0;
    color: #2c3e50;
    border: none;
  }


  .conclusive-firstArea{
    display: flex;
    align-items: flex-start;
  }
  .conclusive-firstArea-tags{
    text-align: left;
    display: flex;
    align-items: baseline;
    flex-shrink:1;
  }
  .conclusive-firstArea:hover .conclusive-firstArea-icon.show{
    opacity: 1;
    pointer-events: unset;
  }
  .conclusive-firstArea-icon{
    font-size:0;
    opacity: 0;
    cursor:pointer;
    pointer-events: none;
  }
  /*.conclusive-firstArea > span:not(.excluded),*/
  /*.conclusive-firstArea > div:not(.excluded){*/
  /*  margin-top: 9px;*/
  /*}*/
  .conclusive-firstArea-questionnaire{
    width: 100px;
    flex-shrink:0;
  }
  .conclusive-label-icon{
    width: 18px;
    height: 18px;
    font-size: 18px;
    margin-right: 5px;
    flex-shrink:0;
  }
  .conclusive-label-text{
    width: 100px;
    line-height: 24px;
    text-align:left;
    flex-shrink:0;
  }
  .tag-inputTool-expanded{
    white-space: nowrap;
  }
  .conclusive-label-btn{
    padding: 0!important;
    color:var(--lightBlue);
    cursor:pointer;
  }

</style>
