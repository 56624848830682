<template>
    <div class="selection-creating" :class="{'disabled': disabled}">
          <div class="el-input  el-input--suffix" :class="{'disabled': disabled}" @click="onInputWrapperClicked">
            <input id="select-input"
                   class="el-input__inner"
                   :class="{'disabled': disabled}"
                   type="text"
                   readonly=""
                   autocomplete="off"
                   placeholder="请选择"
                   style="cursor: pointer">
            <span class="el-input__suffix" style="pointer-events: none">
                <span class="el-input__suffix-inner">
                    <i class="el-select__caret el-input__icon el-icon-arrow-down"
                       :class="{
                      'is-reverse':selectionMenuVisible,
                    }"
                    ></i>
                </span>
            </span>
          </div>


        <div class="selection-dropdown-menu el-select__popper el-popper is-light is-pure" :class="{hide:!selectionMenuVisible}">
          <div class="el-popper__arrow"></div>
          <el-scrollbar class="selection-options-scroller">
            <div v-for="(item, index) in options"
                 class="selection-dropdown-menu-item"
                 @click="onOptionSelected(item)"
            >
              <span>{{item[labelKey]}}</span>
              <img class="delete-img" src="../assets/images/delete-red.png" @click.stop="remove(item, index)"/>
            </div>
          </el-scrollbar>
          <div>
            <div class="line"></div>
            <div class="add-category-label">新建分类</div>
            <el-input
                v-model="inputValue"
                :input-style="{
                            'border-radius': 0,
                            border: '0',
                            background: '#F4F4F4',
                            padding: '0 0 0 2px',
                            height: '30px',
                          }"
                placeholder="请输入标签"
                style="height: 30px;line-height: 30px;"
            ></el-input>
            <div class="btns">
              <el-button
                  size="mini"
                  style="border-radius: 0;"
                  type="default"
                  class="change-overview-cancel-btn"
                  @click="handleCancel"
              >取消
              </el-button>
              <el-button
                  size="mini"
                  type="primary"
                  class="change-overview-confirm-btn"
                  @click="createConfirm(inputValue)"
              >保存
              </el-button>
            </div>
          </div>
        </div>

    </div>

</template>

<script>
    import {
    } from "../api/api";
    import {ElMessage} from "element-plus";
    import {DomEventListenerManager, MetaMessage} from "../util";

    export default {
        name: "SelectionCreating",
        props: ['options', 'bindData', 'labelKey', 'valueKey', 'selectChange', 'createConfirm', 'remove', 'disabled'],
        mounted() {
            this.inputDom = document.querySelector('#select-input');
            if (this.inputDom && this.selectedValue && this.labelKey && this.valueKey) {
                for (const item of this.options) {
                    if (item[this.valueKey] === this.selectedValue) {
                        this.inputDom.value = item[this.labelKey];
                        break;
                    }
                }
            }
        },
        data() {
            return {
                metaMessage: new MetaMessage(),
                selectionMenuVisible: false,
                selectedValue: this.bindData,
                inputDom: null,
                inputValue: '',
                domEventListenerManager:new DomEventListenerManager(),
                globalFunction:null,
            }
        },
        created() {
          this.globalFunction = (e) => {
            if(e.target.closest('.selection-creating')){
              return;
            }else{
              this.selectionMenuVisible = false;
            }
          }
          this.domEventListenerManager.registerListener(window,'click',this.globalFunction,{capture:true})
        },
        unmounted() {
          this.domEventListenerManager.removeListener(window,'click',this.globalFunction);
        },
        methods: {
            clearInput: function () {
                this.inputValue = '';
            },
            onOptionSelected: function (item) {
                this.inputDom.value = item[this.labelKey];
                this.selectChange(item[this.valueKey]);
                this.selectionMenuVisible = false;
            },
            handleCancel(){
              this.selectionMenuVisible = false;
            },
            setInputValue: function (value) {
                this.inputDom.value = value;
            },
            onInputWrapperClicked: function () {
                if (!this.disabled) {
                    this.selectionMenuVisible = !this.selectionMenuVisible;
                }
            }
        }

    }
</script>

<style scoped>

    .line {
        background-color: var(--el-border-color-base);
        position: relative;
        height: 1px;
    }

    .add-category-label {
        color: #8a8a8a;
        margin: 10px 0;
    }

    .btns {
        padding: 10px 0  0;
        text-align: end;
    }

    .selection-dropdown-menu {
      position: absolute;
        top: calc(100% + 12px);
        border-radius: 2px;
        list-style: none;
        z-index: 999;
        background: white;
        width: 191px;
      transform-origin: top;
      transition: .2s cubic-bezier(0, 0.8, 0.26, 0.95);
      box-shadow: var(--el-box-shadow-light);
      padding: 5px 13px;
      border-radius: 4px;
    }
    .selection-dropdown-menu .el-popper__arrow{
      transform: translate(-50%,-50%);

      left:50%;
      top: 0;
    }
    .selection-dropdown-menu .el-popper__arrow::before{
      border-bottom-color: transparent;
      border-right-color: transparent;
    }
    .selection-dropdown-menu.hide{
      transform:scaleY(0);
      opacity: 0;


    }

    .selection-dropdown-menu-item {
        font-size: var(--el-font-size-base);
        /*padding: 0 32px 0 20px;*/
        position: relative;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--el-text-color-regular);
        height: 34px;
        line-height: 34px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
    }

    .selection-dropdown-menu-item.selected {
        color: var(--el-color-primary);
        font-weight: 700;
    }

    .selection-dropdown-menu-item:hover {
        background-color: rgb(245, 247, 250);
    }

    .selection-dropdown-menu-item:hover img {
        display: inline-block;
    }

    .delete-img {
        display: none;
        margin-top: 9px;
        width: 16px;
        height: 16px;
    }

    .disabled {
        background-color: #f5f7fa;
        cursor: not-allowed !important;
        pointer-events:none !important;
    }
    .el-icon-arrow-down{
      transition: .3s ease;
    }
    .el-icon-arrow-down.is-reverse{
      transform: rotateZ(-180deg);
    }
</style>
<style>
    .selection-options-scroller .el-scrollbar__view {
        max-height: 200px;
        overflow-y: auto;
    }
</style>
